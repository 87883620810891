.side-panel-open .body-overlay {
  opacity: 1;
  z-index: 599;
  pointer-events: auto;
}

.body-overlay {
  cursor:
    url('../../../src/assets/images/close.png') 15 15,
    default;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
  pointer-events: none;
  transition: opacity 0.3s ease;
  box-sizing: border-box;
  display: block;
}
