.logo {
  width: fit-content;

  a {
    display: flex;
  }

  &-image {
    height: auto;
    min-width: 90px;
    max-width: 100%;
    object-fit: cover;
    border-radius: 0px 0px 15px 0px;

    &:hover {
      box-shadow: 0px 4px 15px 0px rgba(93, 95, 239, 0.6), 0px -4px 15px 0px rgba(235, 0, 0, 0.3);
    }
  }
}
