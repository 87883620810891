.website {
  position: fixed;
  height: 96px;
  width: 98vw;
  background-color: #f3f3f3;
  box-shadow: 0 var(--spacing-xxxs) var(--spacing-xxs) var(--spacing-xxxs) var(--color-grey-3);
  color: var(--color-black);
  z-index: 10;

  &-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 60%;
    max-width: 1140px;
    height: 48px;
    gap: 5%;
    margin: auto;
    margin-top: 24px;

    &-actions {
      display: flex;
      gap: 64px;
      margin-left: auto;
      align-items: center;

      &-login:hover {
        box-shadow:
          0px 4px 15px 0px rgba(93, 95, 239, 0.6),
          0px -4px 15px 0px rgba(235, 0, 0, 0.3);
      }

      &-local {
        display: flex;
        margin-left: auto;
        gap: 32px;
        align-items: flex-end;
        justify-content: flex-end;

        &-pricing,
        &-download {
          background: none;
          border: none;
          position: relative;
          color: #252525;
          transition:
            color 0.3s,
            background 0.3s;
        }

        &-pricing:hover,
        &-download:hover {
          background-image: linear-gradient(
            to bottom,
            rgba(235, 0, 0, 0.3),
            rgba(93, 95, 239, 0.6)
          );
          color: transparent;
          -webkit-background-clip: text;
          background-clip: text;
          text-shadow:
            0px 4px 15px rgba(93, 95, 239, 0.6),
            0px -4px 15px rgba(235, 0, 0, 0.3);
        }

        @media (max-width: 450px) {
          gap: 16px;
        }
      }

      @media (max-width: 1000px) {
        display: none;
      }
    }

    &-mobile {
      display: none;

      &-local {
        display: none;
      }

      &-login,
      &-bars {
        font-size: 25px;
        height: 43px;
        width: 43px;
        border: 2px solid #dadada;
        padding: 8px;
      }

      @media (max-width: 1000px) {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        max-height: 42px;
        height: 42px;
      }
    }

    &-logo {
      height: 42px;
      flex-grow: 1;
      margin-right: calc(var(--spacing-s) + var(--spacing-m));
      max-width: 10rem;
    }

    @media (max-width: 700px) {
      width: 95%;
    }

    @media (max-width: 1000px) {
      margin-top: 12px !important;
    }
  }

  @media (max-width: 1000px) {
    height: 72px;
  }

  &-local {
    display: none;

    @media (max-width: 1000px) {
      flex-direction: column;
      margin-top: 72px;
      height: calc(100vh - 72px);
      width: 100%;
      position: fixed;
      background-color: #f3f3f3;
      z-index: 100;
      padding: 40px 24px 16px 24px;
    }
  }
}
