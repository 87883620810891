.btn {
  border-radius: calc(var(--spacing-xl) + var(--spacing-xxxl));
  cursor: pointer;
  font-family: var(--font-bold);
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(var(--spacing-s) + var(--spacing-m));
  line-height: calc(var(--spacing-s) + var(--spacing-m));

  &-primary {
    background-color: var(--color-black);
    color: var(--color-white);

    &:hover {
      background-color: var(--color-blue-2);
    }

    &.btn-full {
      border: var(--spacing-xxs) solid transparent;
      padding: var(--spacing-l);
      &.btn-highlight {
        border: var(--spacing-xxs) solid var(--color-yellow-3);
      }
    }
  }

  &-secondary {
    background-color: var(--color-grey-0);
    color: var(--color-black);

    &:hover {
      background-color: var(--color-white);
    }
  }

  &-tertiary {
    background-color: var(--color-white);
    color: var(--color-black);
  }

  &-danger {
    background-color: var(--color-red-3);
    color: var(--color-white);

    &:hover {
      background-color: var(--color-red-1);
    }
  }

  &-small {
    padding: var(--spacing-s) var(--spacing-m);
  }

  &-field {
    padding: var(--spacing-m) var(--spacing-xl);
  }

  &-default {
    padding: var(--spacing-l) var(--spacing-xxl);
  }

  &-full {
    height: 100%;
    padding: calc(var(--spacing-s) + var(--spacing-m));
    width: 100%;
  }

  &:disabled {
    background-color: var(--color-grey-0);
    color: var(--color-grey-4);
    cursor: initial;
  }
}
