.privacy-footer {
  position: relative;
  background-color: #f3f3f3;
  border: 0;
  display: block;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  margin-top: 20px;

  &-container {
    float: none;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    text-align: center;
    padding: 20px 15px;
    width: 100%;

    &-links,
    &-contact {
      position: relative;
      width: fit-content;
      padding: 0 15px;
      display: block;
      align-items: center;

      &-header {
        color: #7e7e7e;
        font-weight: bolder;
        font-size: 20px;
        letter-spacing: 3px;
        text-transform: uppercase;
        margin-bottom: 30px;
      }

      @media (min-width: 992px) {
        flex: 1;
        max-width: 50%;
      }
    }

    &-links-list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: block;

      &-item {
        margin: 5px 0;
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: bolder;
        padding: 0;
        display: list-item;
        text-align: center;

        a {
          color: #7e7e7e;
        }
      }
    }

    &-contact-content {
      font-size: 17px;
      letter-spacing: 1px;
      line-height: 1.6;
      margin-bottom: 15px;
      font-weight: bolder;
      display: block;

      a {
        color: #7e7e7e;
      }
    }

    &-contact-icons {
      text-align: center;
      text-shadow: none;
      display: block;

      a {
        width: 32px;
        height: 32px;
        background-color: transparent;
        font-size: 0.875rem;
        line-height: 30px;
        margin: 0 8px 5px 0;
        border-radius: 3px;
        border: 0;
        overflow: hidden;
        text-align: center;
        cursor: pointer;
        font-style: normal;
        float: none;
        display: inline-block;
        text-decoration: none;
        color: #7e7e7e;
      }
    }

    @media (min-width: 992px) {
      max-width: 960px;
    }
  }

  &-copyrights {
    text-align: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 15px;
    padding-left: 15px;
    display: block;
    font-size: 14px;
    font-weight: bolder;
    line-height: 1.8;
    color: #676767;
    background-color: #d6d6d6;
  }
}
