#download-app {
  height: 10px;
}

.header {
  position: relative;
  width: 100vw;
  margin-top: 200px;

  &-pill-horizontal {
    position: absolute;
    z-index: -1;
    background: linear-gradient(
      to bottom,
      rgba(255, 148, 148, 0.3) 0%,
      rgba(133, 115, 255, 0.3) 100%
    );
    border-radius: 0 833px 833px 0;
    width: 0%;
    transition: width 0.5s ease-in-out;
    left: 0%;

    @media (min-width: 251px) and (max-width: 450px) {
      border-radius: 0 200px 200px 0;
    }

    @media (min-width: 451px) and (max-width: 750px) {
      border-radius: 0 350px 350px 0;
    }

    &.visible {
      width: 81%;

      @media (max-width: 750px) {
        width: 100%;
      }

      @media (min-width: 951px) and (max-width: 1100px) {
        width: 83%;
      }
    }
  }

  &-pill-angled {
    position: absolute;
    z-index: -1;
    background: linear-gradient(
      to bottom,
      rgba(255, 148, 148, 0.3) 0%,
      rgba(133, 115, 255, 0.3) 100%
    );
    border-radius: 833px 0 0 833px;
    width: 0%;
    transition:
      width 0.5s ease-in-out,
      left 0.5s ease-in-out,
      top 0.5s ease-in-out;
    top: 500px;
    left: 110%;
    transform: rotate(30deg);

    @media (min-width: 651px) and (max-width: 750px) {
      top: 300px;
    }

    @media (min-width: 751px) and (max-width: 950px) {
      top: 250px;
    }

    @media (min-width: 951px) and (max-width: 1100px) {
      top: 370px;
    }

    @media (min-width: 1101px) and (max-width: 1350px) {
      top: 400px;
    }

    @media (min-width: 1351px) and (max-width: 1500px) {
      top: 450px;
    }

    &.visible {
      top: 210px;
      width: 75%;
      left: 52.5%;

      @media (max-width: 450px) {
        top: 190px;
        width: 100%;
        left: 53%;
      }

      @media (min-width: 451px) and (max-width: 550px) {
        top: 340px;
        width: 100%;
        left: 47%;
      }

      @media (min-width: 551px) and (max-width: 650px) {
        top: 340px;
        width: 90%;
        left: 47%;
      }

      @media (min-width: 651px) and (max-width: 750px) {
        top: 40px;
        width: 80%;
        left: 47%;
      }

      @media (min-width: 751px) and (max-width: 950px) {
        top: 100px;
        width: 85%;
        left: 45%;
      }

      @media (min-width: 951px) and (max-width: 1100px) {
        top: 147px;
        width: 83%;
        left: 50%;
      }

      @media (min-width: 1101px) and (max-width: 1350px) {
        top: 100px;
        width: 75%;
        left: 45%;
      }

      @media (min-width: 1351px) and (max-width: 1500px) {
        top: 158px;
        left: 46%;
      }
    }
  }

  &-nonpill {
    display: flex;
    width: 60%;
    max-width: 1000px;
    gap: 40px;
    margin: 0 auto;

    &-content {
      flex: 1;
      gap: 16px;
      margin: auto 0;

      &-title {
        font-family: var(--font-bold);
        font-size: 60px;
        color: #252525;

        @media (max-width: 700px) {
          text-align: center;
        }

        span {
          text-decoration: underline;
        }

        @media (min-width: 1101px) and (max-width: 1440px) {
          font-size: 50px;
          line-height: 50px;
        }

        @media (min-width: 901px) and (max-width: 1100px) {
          font-size: 42px;
        }

        @media (min-width: 701px) and (max-width: 900px) {
          font-size: 36px;
        }

        @media (min-width: 401px) and (max-width: 500px) {
          font-size: 36px;
        }

        @media (min-width: 301px) and (max-width: 400px) {
          font-size: var(--font-size-xxl);
        }

        @media (max-width: 300px) {
          font-size: var(--font-size-xl);
        }
      }

      &-text {
        font-family: var(--font-semi-bold);
        font-size: var(--font-size-l);
        color: #7e7e7e;
        margin-top: 10px;

        @media (min-width: 301px) and (max-width: 400px) {
          font-size: var(--font-size-m);
        }

        @media (max-width: 300px) {
          font-size: var(--font-size-s);
        }

        @media (max-width: 700px) {
          text-align: center;
        }

        @media (min-width: 1101px) and (max-width: 1440px) {
          margin-top: 20px;
        }
      }

      &-actions {
        display: flex;
        gap: 10px;
        margin-top: 16px;

        &-apple,
        &-google {
          flex: 1;
          width: 90%;
          max-width: 144px;
          height: 45px;
          border: 2px solid #a6a6a6;
          border-radius: 10px;

          @media (min-width: 501px) and (max-width: 600px) {
            border: 1px solid #a6a6a6;
            height: 35px;
          }

          @media (min-width: 601px) and (max-width: 750px) {
            border: 1px solid #a6a6a6;
            height: 40px;
          }

          @media (min-width: 401px) and (max-width: 500px) {
            border: 1px solid #a6a6a6;
            height: 30px;
          }

          @media (max-width: 450px) {
            border: 1px solid #a6a6a6;
            height: 25px;
          }
        }

        &-apple:hover,
        &-google:hover {
          box-shadow:
            0px 4px 15px 0px rgba(93, 95, 239, 0.4),
            0px -4px 15px 0px rgba(235, 0, 0, 0.2);
        }

        @media (min-width: 901px) and (max-width: 1440px) {
          margin-top: 10px;
        }

        @media (max-width: 700px) {
          justify-content: center;
        }
      }
    }

    &-image {
      flex: 1;

      &-container {
        position: relative;
        display: inline-block;
        margin: 0 auto;
        overflow: hidden;
        width: 100%;
        height: fit-content !important;

        &-image1,
        &-image2,
        &-tabell,
        &-manell {
          position: absolute;
        }

        &-image1 {
          top: 0;
          left: 30%;
          height: 70%;
          width: 60%;
          z-index: 1;
          transition:
            width 3s ease-out,
            height 4s ease-out;
        }

        &-image2 {
          top: 20%;
          left: 0;
          width: 90%;
          height: 90%;
          z-index: 2;
          transition:
            width 4s ease-out,
            height 4s ease-out;
        }

        &-banner {
          width: 100%;
          max-width: 513px;
          height: auto;
          max-height: 482px;
          margin: 0 auto;

          @media (max-width: 700px) {
            max-width: 325px;
            max-height: 305px;
            display: flex;
          }
        }

        &-tabell {
          right: -5%;
          width: 100%;
          height: 100%;
          max-width: 320px;
          max-height: 280px;

          @media (max-width: 700px) {
            display: none;
          }
        }

        &-manell {
          top: 10%;
          left: -10%;
          width: 90%;
          height: 100%;
          max-width: 450px;
          max-height: 450px;

          @media (max-width: 700px) {
            display: none;
          }
        }
      }

      @media (max-width: 700px) {
        order: -1;
        min-width: 200px;
      }
    }

    .image1-expanded {
      height: 80%;
      width: 70%;
    }

    .image2-expanded {
      top: 20%;
      height: 100%;
      width: 100%;
    }

    @keyframes widthAnimation {
      from {
        height: 90%;
        width: 90%;
      }
      to {
        height: 100%;
        width: 100%;
      }
    }

    @media (max-width: 700px) {
      flex-direction: column-reverse;
      gap: 30px;
      width: 90%;
    }
  }

  @media (max-width: 700px) {
    margin-top: 150px;
  }
}
