#pricing {
  height: 10px;
}

.pricings {
  position: relative;
  width: 100%;
  min-height: 593px;
  margin-top: 115px;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &-description {
    width: fit-content;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &-header {
      width: fit-content;
      margin: 0 auto;
      font-size: 48px;
      font-weight: 600;
      font-family: var(--font-bold);

      @media (max-width: 900px) {
        font-size: 36px;
      }
    }

    &-text {
      max-width: 600px;
      font-size: var(--font-size-m);
      font-family: var(--font-semi-bold);
      text-align: center;

      @media (max-width: 900px) {
        font-size: var(--font-size-s);
        text-align: left;
      }
    }
  }

  &-period {
    display: flex;
    gap: 8px;
    max-width: 280px;
    margin: 0 auto;

    &-monthly,
    &-yearly {
      margin: auto;
      font-family: var(--font-semi-bold);
    }
  }

  &-bigger {
    margin-top: -50px;
    width: fit-content;
    max-width: 60%;
    margin: 0 auto;
    text-align: center;
    border: 1px solid #cdcdcd;
    border-radius: 16px;
    color: #000000;
    padding: 15px;
    font-family: var(--font-bold);
    font-size: 16px;

    a {
      width: fit-content;
      color: #000000;
      text-decoration: none;
      cursor: pointer;
    }

    a:hover {
      color: #000000;
    }

    @media (max-width: 900px) {
      max-width: 90%;
    }
  }

  &-plans {
    display: flex;
    max-width: 1140px;
    width: 60%;
    gap: 40px;
    margin: 0 auto;

    &-most-popular {
      position: absolute;
      background: #000000;
      color: #ffffff;
      padding: 2px 8px;
      border: 2px solid #ffffff;
      border-radius: 100px;
      left: calc(50% - 52px);
      top: -15px;
    }

    &-division,
    &-premier {
      border: 1px solid #cdcdcd;
      border-radius: 16px;

      &-actions-select-plan {
        background: linear-gradient(
          to bottom,
          rgba(255, 148, 148, 0.3) 0%,
          rgba(133, 115, 255, 0.3) 100%
        );
      }
    }

    &-championship {
      p {
        color: #000000;
      }
      background: linear-gradient(
        to bottom,
        rgba(255, 148, 148, 0.3) 0%,
        rgba(133, 115, 255, 0.3) 100%
      );
      border-radius: 16px;

      &-actions-select-plan {
        background: #ffffff;
      }

      &-benefits {
        p:not(:last-child) {
          border-bottom: 1px solid #000000 !important;
        }
      }
    }

    &-division,
    &-championship,
    &-premier {
      position: relative;
      width: calc(33% - 80px);
      padding: 40px 16px 16px 16px;
      display: flex;
      flex-direction: column;
      gap: 40px;
      min-height: 400px;
      height: fit-content;

      @media (max-width: 900px) {
        width: 100%;
      }

      &-description {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: fit-content;
        max-height: fit-content;

        &-header {
          font-family: var(--font-bold);
          font-size: 24px;
        }

        &-start {
          margin-top: 16px;
          font-size: 9px;
        }

        &-price {
          font-family: var(--font-bold);
          font-size: 32px;
          position: relative;

          span {
            position: absolute;
            top: 6px;
            font-size: 14px;
          }
        }

        &-text {
          font-family: var(--font-semi-bold);
          font-size: 16px;
          margin-top: 8px;
        }
      }

      &-benefits {
        display: flex;
        flex-direction: column;
        max-height: fit-content;

        p {
          font-family: var(--font-medium);
        }

        p:not(:last-child) {
          border-bottom: 1px solid #d9d9d9;
          width: 100%;
          padding-bottom: 8px;
        }

        p:not(:first-child) {
          padding-top: 8px;
        }
      }

      &-actions {
        position: absolute;
        bottom: 16px;
        left: calc(50% - 61px);

        &-select-plan {
          padding: 8px 32px;
          border-radius: 74px;
          font-family: var(--font-bold);
          font-size: 16px;
          width: fit-content;
          height: fit-content;

          :hover {
            cursor: pointer;
          }
        }
      }
    }

    @media (max-width: 900px) {
      flex-direction: column;
      width: 90%;
    }
  }

  &-taxes {
    width: fit-content;
    margin: auto;
    font-size: 9px;
  }
}

.toggle-container {
  width: 60px;
  height: 30px;
  background-color: #ccc;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-circle {
  width: 20px;
  max-width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-container.on {
  background: linear-gradient(
    to bottom,
    rgba(255, 148, 148, 0.3) 0%,
    rgba(133, 115, 255, 0.3) 100%
  );
}

.toggle-container.on .toggle-circle {
  transform: translateX(30px);
}

.toggle-container.off {
  background: #cdcdcd;
}

.toggle-container.off .toggle-circle {
  transform: translateX(0);
}
