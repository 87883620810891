.features {
  min-height: 2550px;
  display: flex;
  flex-direction: column;
  margin-top: 200px;
  margin-bottom: 100px;

  &-content {
    flex: 1;
    justify-content: center;
    height: fit-content;
    margin-bottom: 170px;
  }

  @media (max-width: 700px) {
    margin-bottom: 0px;
    margin-top: 170px;
  }
}
