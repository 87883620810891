.navbar {
  color: #eee;
  display: block;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;

  ::after,
  ::before {
    box-sizing: border-box;
  }

  &-wrap {
    &-clone {
      height: 71px;
    }

    &-row {
      width: 100%;
      max-width: none;
      padding: 0 30px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      position: relative;

      &-logo {
        min-width: 70px;
        position: relative;
        align-items: center;
        max-height: none;
        color: #fff;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 36px;
        text-decoration: none;
        background-color: transparent;
        flex-grow: 1;

        img {
          height: 70px;
          width: 70px;
          display: block;
          max-width: 100%;
          max-height: 100%;
          border: none;
        }

        :-webkit-any-link {
          cursor: pointer;
        }

        &-standard {
          display: none;
        }

        &-retina {
          display: flex;
          width: fit-content;
        }

        @media (min-width: 992px) {
          border-right-color: rgba(255, 255, 255, 0.15);
          border-right: 0;
          padding-right: 30px;
        }

        @media (max-width: 991px) {
          margin-right: auto;
        }
      }

      &-misc {
        position: initial;
        display: flex;
        align-items: center;
        margin-left: 15px;
        flex: 0 0 51px;
        max-width: 51px;

        &-icon {
          margin: 0 8px;
          display: block;

          a {
            color: #444;
            display: block;
            position: relative;
            width: 20px;
            height: 20px;
            font-size: 1.125rem;
            line-height: 20px;
            text-align: center;
            text-decoration: none;
            background-color: transparent;

            i {
              display: inline-block;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: inherit;
              -webkit-font-smoothing: antialiased;

              ::before {
                content: '\e9ee';
              }
            }
          }
        }

        @media (min-width: 992px) {
          order: 13;
        }
      }

      &-primary-menu {
        display: flex;
        align-items: center;

        &-container {
          display: block;
          list-style: none;
          margin: 0;
          width: 100%;
          padding: 0;
          flex-basis: 100%;

          &-item {
            position: relative;
            margin: 0;
            padding: 0;
            display: list-item;
            text-align: -webkit-match-parent;

            &-link {
              display: block;
              line-height: 22px;
              color: #444;
              text-transform: uppercase;
              text-decoration: none;
              cursor: pointer;

              @media (min-width: 992px) {
                padding-left: 24px;
                padding-right: 24px;
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 3px;
              }
            }

            @media (max-width: 991px) {
              padding: 10px 0;
              border-bottom: 1px solid #ccc;
            }
          }

          @media (min-width: 992px) {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding-right: 0;
            margin-right: 0;
            border-right: 0;
          }
        }

        @media (max-width: 991px) {
          width: 100%;
          margin: 0 auto;
        }

        @media (min-width: 992px) {
          flex: 0 0 auto;
        }
      }

      @media (min-width: 992px) {
        position: fixed;
        margin-top: 0;
        border-bottom-color: rgba(255, 255, 255, 0.15);
        border-bottom: 1px solid;
        background-color: #ffffff;
      }

      @media (max-width: 991px) {
        position: initial;
        border-bottom-color: rgba(255, 255, 255, 0.15);
        border-bottom: 1px solid;
      }
    }
  }

  @media (min-width: 992px) {
    background: transparent;
    border-bottom: 1px solid transparent;
    z-index: 299;
  }
}

#primary-menu-trigger {
  color: #444;
  cursor: pointer;
  font-size: 0.875rem;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: flex;
  opacity: 1;
  pointer-events: auto;
  z-index: 1;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;

  @media (min-width: 992px) {
    display: none;
    opacity: 0;
    pointer-events: none;
    z-index: 0;
  }

  @media (max-width: 991px) {
    flex: 0 0 70px;
  }
}

.current {
  a:hover {
    color: #1abc9c;
  }
}
