.footer {
  position: relative;
  margin-bottom: auto;
  width: 100%;
  background-color: #f3f3f3;
  min-height: 50px;
  height: max-content;
  padding: 10px 100px 10px 100px;

  &-actions {
    display: flex;
    gap: 15%;
    min-height: 50px;
    justify-content: center;
    margin-top: 10px;
    font-family: var(--font-bold);
    font-size: var(--font-size-s);
    line-height: 20px;
    text-align: center;

    &-privacy,
    &-eula,
    &-consent,
    &-terms {
      color: #7e7e7e;
      height: 20px;
    }

    @media (max-width: 400px) {
      font-size: var(--font-size-xxs);
    }

    @media (max-width: 700px) {
      margin-top: 0px;
    }
  }

  &-text {
    height: fit-content;
    min-height: 20px;
    font-family: var(--font-semi-bold);
    font-size: var(--font-size-s);
    text-align: center;
    color: #7e7e7e;

    a {
      color: #7e7e7e;
    }

    @media (max-width: 400px) {
      font-size: var(--font-size-xxs);
    }
  }

  @media (max-width: 700px) {
    padding: 10px 10px 10px 10px;
    margin-bottom: 20px;
  }
}
