.input {
  &-field {
    padding: var(--spacing-xs);
    text-align: left;

    &:hover {
      background-color: var(--color-white);
    }

    &:active,
    &:focus {
      background-color: var(--color-white);
    }

    &::placeholder {
      color: var(--color-grey-5);
    }

    &:disabled {
      background-color: transparent;

      &::placeholder {
        color: var(--color-grey-3);
      }
    }
  }

  &-error {
    color: var(--color-red-3);
    display: inline-block;
    font-size: var(--font-size-xs);
    margin-left: var(--spacing-xs);
  }
}
