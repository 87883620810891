.new-features {
  margin-top: 200px;
  min-height: 337px;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 148, 148, 0.1) 0%,
    rgba(133, 115, 255, 0.1) 100%
  );
  margin-bottom: 250px;

  &-box {
    width: 60%;
    min-height: 337px;
    height: 100%;
    border-radius: 200px;
    padding: 48px 24px 48px 24px;
    margin: 0 auto;
    background: linear-gradient(
      to bottom,
      rgba(255, 148, 148, 0.3) 0%,
      rgba(133, 115, 255, 0.3) 100%
    );

    &-title {
      min-height: 61px;
      height: fit-content;
      width: fit-content;
      max-width: 80%;
      margin: 0 auto;
      font-family: var(--font-bold);
      font-size: 48px;
      color: #252525;
      margin-bottom: 16px;
      text-align: center;

      @media (max-width: 300px) {
        font-size: var(--font-size-m);
      }

      @media (min-width: 301px) and (max-width: 400px) {
        font-size: var(--font-size-l);
      }

      @media (min-width: 401px) and (max-width: 500px) {
        font-size: var(--font-size-xl);
      }

      @media (min-width: 501px) and (max-width: 950px) {
        font-size: var(--font-size-xxl);
      }

      @media (max-width: 700px) {
        margin-bottom: 5px;
      }
    }

    &-text {
      min-height: 28px;
      width: fit-content;
      margin: 0 auto;
      font-family: var(--font-semi-bold);
      font-size: var(--font-size-l);
      color: #4b4b4b;
      margin-bottom: 48px;
      text-align: center;

      @media (max-width: 300px) {
        font-size: var(--font-size-xs);
      }

      @media (min-width: 301px) and (max-width: 400px) {
        font-size: var(--font-size-s);
      }

      @media (min-width: 401px) and (max-width: 500px) {
        font-size: var(--font-size-m);
      }

      @media (max-width: 700px) {
        margin-bottom: 24px;
      }
    }

    &-actions {
      min-width: 50%;

      &-form {
        width: fit-content;
        min-height: 48px;
        display: flex;
        margin: 0 auto;
        background: #fafafa;
        border-radius: 35px;
        padding: 10px 10px 15px 10px;
        gap: 20px;

        &-input {
          flex: 2;
          width: 100%;
          margin: auto 0;

          @media (max-width: 900px) {
            margin: 0 auto;
          }
        }

        &-input input {
          text-align: left !important;
        }

        &-input input:hover {
          background-color: #fafafa;
        }

        &-button {
          flex: 1;

          @media (max-width: 900px) {
            margin: 0 auto;
          }

          &-submit:hover {
            box-shadow:
              0px 4px 15px 0px rgba(93, 95, 239, 0.6),
              0px -4px 15px 0px rgba(235, 0, 0, 0.3);
          }
        }

        @media (max-width: 900px) {
          display: grid;
          padding: 10px 4px 15px 10px;
          gap: 10px;
        }
      }
    }

    @media (max-width: 400px) {
      width: 85%;
    }
  }

  @media (max-width: 700px) {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
