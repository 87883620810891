.content-summary {
  font-family: Arial;
  background: transparent;
  box-sizing: border-box;
  line-height: 1.5;
  font-size: var(--font-size-xs);
  color: #595959;
  font-weight: 400;
  display: block;

  strong {
    font-weight: bolder;
  }

  a {
    word-break: break-word;
    color: #3030f1;
    text-decoration: none;
  }

  a:-webkit-any-link {
    cursor: pointer;
  }

  &-title {
    font-size: 19px;

    span {
      color: #000000;
    }
  }

  &-subtitle {
    em {
      font-style: italic;
    }
  }

  &-paragraph {
  }
}
