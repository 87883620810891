.testimonials {
  width: 100%;
  min-height: 570px;
  gap: 24px;

  &-header {
    width: fit-content;
    height: 61px;
    margin: 0 auto;
    font-family: var(--font-bold);
    font-size: 48px;
    color: #252525;
    margin-bottom: 125px;

    @media (max-width: 700px) {
      font-size: 36px;
      text-align: center;
      margin-bottom: 50px;
    }
  }
}
