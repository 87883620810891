.privacy-side-panel {
  background-color: #383838;
  position: fixed;
  top: 0;
  right: 300px;
  z-index: 699;
  width: 300px;
  height: 100%;
  overflow: hidden;
  color: #eee;
  box-sizing: border-box;
  display: block;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;

  &-trigger {
    a {
      display: none;
      text-decoration: none;
      color: #1abc9c;
      background-color: transparent;
    }

    a:webkit-any-link {
      cursor: pointer;
    }

    @media (max-width: 991px) {
      color: #f7f7f7;
      background-color: rgba(0, 0, 0, 0.3);
      display: block;
      position: absolute;
      z-index: 12;
      top: 0;
      left: auto;
      right: 0;
      width: 40px;
      height: 40px;
      font-size: 18px;
      line-height: 40px;
      text-align: center;
      border-radius: 0 0 0 2px;

      a {
        display: initial;
        color: #ffffff;
      }
    }
  }

  &-wrap {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 330px;
    height: 100%;
    overflow: auto;
    padding: 50px 70px 50px 40px;

    &-widget {
      width: 220px;
      margin-top: 40px;
      padding-top: 40px;
      position: relative;

      h4 {
        margin-bottom: 25px;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-family: 'Source Sans Pro', sans-serif;
        line-height: 1.5;
        margin: 0 0 30px 0;
        display: block;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }

      &-links {
        font-size: 14px;
        line-height: 1.7;

        address {
          line-height: 1.7;
          margin-bottom: 30px;
          font-style: normal;
          display: block;
        }

        abbr[title] {
          text-decoration: underline dotted;
          cursor: help;
          border-bottom: 0;
          text-decoration-skip-ink: none;
        }
      }

      &-icon {
        color: #eee;
        font-size: 0.875rem;
        line-height: 30px;
        border-color: transparent;
        text-shadow: none;
        overflow: hidden;
        display: block;
        float: left;
        text-align: center;
        cursor: pointer;
        font-style: normal;
        text-decoration: none;

        a {
          margin-right: 5px;
        }

        &-facebook,
        &-github,
        &-linkedin,
        &-instagram,
        &-skype {
          display: inline-block;
          width: 28px;
          height: 28px;
        }

        &-facebook,
        &-facebook:hover {
          color: #1877f2;
        }

        &-github,
        &-github:hover {
          color: #000000;
        }

        &-linkedin,
        &-linkedin:hover {
          color: #0072b1;
        }

        &-instagram,
        &-instagram:hover {
          color: #8a3ab9;
        }

        &-skype,
        &-skype:hover {
          color: #00aff0;
        }
      }
    }

    &-widget::after {
      display: block;
      clear: both;
      content: '';
    }

    &-widget:first-child {
      padding-top: 0;
      border-top: 0;
      margin-top: 0;
    }

    &-widget:not(:first-child) {
      border-top: 1px solid rgba(255, 255, 255, 0.15);
    }
  }
}
