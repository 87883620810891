/* setting the global fonts faces */
@font-face {
  font-family: 'Light';
  src: local('Rajdhani-Light'), url('./assets/fonts/Rajdhani-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Regular';
  src: local('Rajdhani-Regular'), url('./assets/fonts/Rajdhani-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Medium';
  src: local('Rajdhani-Medium'), url('./assets/fonts/Rajdhani-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'SemiBold';
  src: local('Rajdhani-SemiBold'), url('./assets/fonts/Rajdhani-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Bold';
  src: local('Rajdhani-Bold'), url('./assets/fonts/Rajdhani-Bold.ttf') format('truetype');
}

/* setting the variables */
:root {
  --color-white: #ffffff;
  --color-grey-0: #f2f2f2;
  --color-grey-1: #f9f9f9;
  --color-grey-2: #efefef;
  --color-grey-3: #e3e3e3;
  --color-grey-4: #bdbdbd;
  --color-grey-5: #6c6c6c;
  --color-red-1: #eb0000;
  --color-red-2: #9b1b1b;
  --color-red-3: #eb5757;
  --color-yellow-1: #faff00;
  --color-yellow-2: #bdff00;
  --color-yellow-3: #d8dc00;
  --color-green-1: #059142;
  --color-blue-1: #5d5fef;
  --color-blue-2: #0f2351;
  --color-black: #000000;

  --font-light: 'Light', sans-serif;
  --font-regular: 'Regular', sans-serif;
  --font-medium: 'Medium', sans-serif;
  --font-semi-bold: 'SemiBold', sans-serif;
  --font-bold: 'Bold', sans-serif;

  --font-size-xxs: 10px;
  --font-size-xs: 14px;
  --font-size-s: 16px;
  --font-size-m: 18px;
  --font-size-l: 22px;
  --font-size-xl: 28px;
  --font-size-xxl: 32px;

  --spacing-xxxs: 1px;
  --spacing-xxs: 2px;
  --spacing-xs: 4px;
  --spacing-s: 8px;
  --spacing-m: 12px;
  --spacing-l: 16px;
  --spacing-xl: 24px;
  --spacing-xxl: 32px;
  --spacing-xxxl: 48px;
}

*,
*::before,
*::after {
  font-family: var(--font-regular);
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
*::-webkit-scrollbar {
  background-color: rgba(white, 0.3);
  width: var(--spacing-l);
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: rgba(white, 0.3);
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: var(--color-grey-2);
  border-radius: var(--spacing-l);
  border: var(--spacing-xs) solid var(--color-white);
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}

/* set overall links styles */
a {
  color: var(--color-blue-1);

  &:hover,
  &:focus,
  &:active {
    color: var(--color-blue-2);
  }
}

#root {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

div > div:not(.footer) {
  flex: 1;
}
