.avatar {
  /*background-color: var(--color-white);*/
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;

  &-image {
    height: 100%;
    width: 100%;
  }

  &-colors {
    height: 100%;
    width: 100%;

    div {
      min-height: 50%;
    }
  }
}
