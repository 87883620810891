.feature {
  position: relative;
  width: 100%;
  height: fit-content;
  max-height: 600px;
  padding: 0 10px;

  &-pill-left,
  &-pill-right {
    position: absolute;
    z-index: -1;
    background: linear-gradient(
      to bottom,
      rgba(255, 148, 148, 0.3) 0%,
      rgba(133, 115, 255, 0.3) 100%
    );
    min-height: 450px;
    // max-width: 1250px;
    margin-top: 7%;
    width: 80%;

    @media (min-width: 551px) and (max-width: 650px) {
      margin-top: 13%;
    }

    @media (min-width: 651px) and (max-width: 1250px) {
      margin-top: 11%;
    }

    @media (min-width: 1251px) and (max-width: 1800px) {
      margin-top: 8%;
    }
  }

  &-pill-left {
    border-radius: 400px 0px 0px 400px;
    transition: left 0.5s ease-in-out;
    left: 100%;
    right: 0%;

    @media (min-width: 501px) and (max-width: 650px) {
      border-radius: 300px 0px 0px 300px;
    }

    @media (min-width: 301px) and (max-width: 500px) {
      border-radius: 200px 0px 0px 200px;
    }

    @media (min-width: 251px) and (max-width: 300px) {
      border-radius: 150px 0px 0px 150px;
    }

    &.visible {
      left: 20%;
      right: 100%;
      width: 80%;

      @media (max-width: 1300px) {
        left: 20%;
      }
    }
  }

  &-pill-right {
    border-radius: 0px 400px 400px 0px;
    transition: left 0.5s ease-in-out;
    left: -100%;
    right: 20%;

    @media (min-width: 1051px) and (max-width: 1270px) {
      left: -125%;
    }

    @media (min-width: 951px) and (max-width: 1050px) {
      left: -135%;
    }

    @media (min-width: 751px) and (max-width: 950px) {
      left: -170%;
    }

    @media (min-width: 651px) and (max-width: 750px) {
      left: -200%;
    }

    @media (min-width: 501px) and (max-width: 650px) {
      left: -260%;
      border-radius: 0px 300px 300px 0px;
    }

    @media (min-width: 301px) and (max-width: 500px) {
      left: -440%;
      border-radius: 0px 200px 200px 0px;
    }

    @media (min-width: 251px) and (max-width: 300px) {
      left: -535%;
      border-radius: 0px 150px 150px 0px;
    }

    &.visible {
      left: 0%;
      right: 100%;
      width: 80%;
    }
  }

  &-box {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 1140px;
    width: 100%;
    height: 100%;
    gap: 48px;

    &-content {
      flex: 1;
      height: fit-content;
      margin: auto 0;
      max-width: 385px;
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 24px;

      &-title,
      &-text {
        flex: 1;
        color: #252525;
      }

      &-title {
        max-height: max-content;
        height: fit-content;
        font-family: var(--font-bold);
        font-size: 40px;

        @media (max-width: 700px) {
          font-size: var(--font-size-l);
          margin: 0 auto;
        }
      }

      &-text {
        height: 234px;
        font-family: var(--font-semi-bold);
        font-size: var(--font-size-m);

        @media (max-width: 700px) {
          font-size: var(--font-size-s);
        }
      }

      @media (max-width: 700px) {
        width: 90%;
        margin: 0 auto;
        order: -1;
      }
    }

    &-img {
      flex: 2;
      height: 707px;
      max-width: 542px;
      display: flex;
      align-items: center;

      @media (max-width: 700px) {
        width: 70%;
        margin: 0 auto;
      }
    }

    @media (max-width: 700px) {
      flex-direction: column;
    }
  }
}
