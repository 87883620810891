.testimonial {
  width: 50%;
  min-height: 273px;
  padding: 24px 128px 24px 24px;
  border-radius: 150px;
  background-color: #f1f1f1;
  margin-bottom: 24px;
  display: flex;
  gap: 24px;

  &-content {
    flex: 2;
    min-height: 200px;
    width: 60%;
    gap: 16px;

    &-title {
      min-height: 31px;
      width: fit-content;
      font-family: var(--font-bold);
      font-size: 24px;
      line-height: 31px;
      color: #252525;
      margin-bottom: 16px;

      @media (max-width: 850px) {
        text-align: center;
        margin: 0 auto;
        margin-bottom: 16px;
      }
    }

    &-text {
      width: 100%;
      min-height: 104px;
      font-family: var(--font-semi-bold);
      font-size: var(--font-size-m);
      line-height: 26px;
      color: #4b4b4b;
      margin-bottom: 8px;

      @media (max-width: 400px) {
        font-size: var(--font-size-xs);
      }

      @media (min-width: 401px) and (max-width: 700px) {
        font-size: var(--font-size-s);
      }
    }

    &-position {
      width: 100%;
      font-family: var(--font-bold);
      font-size: var(--font-size-m);
      line-height: 28px;
      color: #333333;

      @media (max-width: 850px) {
        text-align: center;
        margin: 0 auto;
      }
    }

    &-rating {
      width: fit-content;
      min-height: 32px;

      @media (max-width: 850px) {
        margin: 0 auto;
      }
    }

    @media (min-width: 701px) and (max-width: 850px) {
      width: 75%;
    }

    @media (min-width: 551px) and (max-width: 700px) {
      width: 85%;
    }

    @media (max-width: 550px) {
      width: 100%;
    }
  }

  &-avatar {
    margin: auto 0;
    filter: grayscale(100%);

    @media (max-width: 850px) {
      margin: 0 auto;
    }
  }

  @media (max-width: 850px) {
    display: grid;
    padding: 24px 24px 24px 24px;
    width: 65%;
    border-radius: 70px;
  }

  @media (min-width: 400px) and (max-width: 850px) {
    justify-items: center;
  }
}
