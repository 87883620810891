/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
 */
*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Reapply the pointer cursor for anchor tags */
a,
button {
  cursor: revert;
}

/* Remove list styles (bullets/numbers) */
ol,
ul,
menu {
  list-style: none;
}

/* For images to not be able to exceed their container */
img {
  max-width: 100%;
}

/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input,
textarea {
  -webkit-user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
  -webkit-appearance: revert;
  appearance: revert;
}

/* reset default text opacity of input placeholder */
::placeholder {
  color: unset;
}

/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
  display: none;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
:where([contenteditable]:not([contenteditable='false'])) {
  -moz-user-modify: read-write;
  -webkit-line-break: after-white-space;
  -webkit-user-modify: read-write;
  -webkit-user-select: auto;
  overflow-wrap: break-word;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable='true']) {
  -webkit-user-drag: element;
}

.container {
  max-width: 1194px;
  width: 100vw;
  margin-right: auto;
}

body {
  height: calc(100vh + 20px);
  max-width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-width: 401px) {
    height: calc(100vh + 20px) !important;
  }
}

html {
  overflow: hidden;
}

.termly-display-preferences {
  @media (max-width: 1000px) {
    left: calc(80% - 50px) !important;
  }

  @media (max-width: 700px) {
    left: 80% !important;
  }

  @media (max-width: 480px) {
    bottom: 100px !important;
  }

  @media (max-width: 400px) {
    font-size: var(--font-size-xxs) !important;
    bottom: 80px !important;
    left: calc(80% - 20px) !important;
  }
}
