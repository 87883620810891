.content-header {
  font-family: Arial;
  box-sizing: border-box;
  line-height: 1.5;
  font-size: var(--font-size-xs);
  font-weight: 400;
  display: block;

  strong {
    font-weight: bolder;
  }

  span {
    color: #595959;
  }

  &-title {
    font-size: 26px;

    span {
      color: #000000;
    }
  }

  &-subtitle {
  }

  &-listTitle {
  }

  &-list {
    list-style-type: square;
    margin-bottom: 30px;
    margin: 0;
    padding: 0;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    color: #555;
    text-align: left;

    &-element {
      ::marker {
        unicode-bidi: isolate;
        font-variant-numeric: tabular-nums;
        text-transform: none;
        text-indent: 0px;
        text-align: start;
        text-align-last: start;
      }

      margin: 0;
      padding: 0;
      display: list-item;
      text-align: -webkit-match-parent;
    }
  }

  &-questions {
  }
}
