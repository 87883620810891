.content-section {
  font-family: Arial;
  box-sizing: border-box;
  line-height: 1.5;
  font-size: var(--font-size-xs);
  font-weight: 400;
  display: block;
  background: transparent;

  strong {
    font-weight: bolder;
  }

  span {
    color: #595959;
  }

  em {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  &-title {
    font-size: 19px;

    span {
      color: #000000;
    }
  }

  &-subtitle {
    font-size: 17px;

    span {
      color: #000000;
    }
  }

  &-list {
    list-style-type: square;
    margin-bottom: 30px;
    margin: 0;
    padding: 0;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    color: #555;
    text-align: left;

    &-element {
      ::marker {
        unicode-bidi: isolate;
        font-variant-numeric: tabular-nums;
        text-transform: none;
        text-indent: 0px;
        text-align: start;
        text-align-last: start;
      }

      margin: 0;
      padding: 0;
      display: list-item;
      text-align: -webkit-match-parent;
    }
  }

  &-table {
    width: 100%;
    margin-bottom: 30px;
    border-collapse: collapse;
    display: table;
    text-indent: initial;
    border-spacing: 2px;
    border-color: gray;
    font-family: 'Roboto', sans-serif;
    text-align: left;

    thead,
    tbody {
      display: table-row-group;
      vertical-align: middle;
      border-color: inherit;
    }

    th,
    td {
      width: 33.8274%;
      border: 1px solid black;
      display: table-cell;
      vertical-align: inherit;
    }
  }
}
