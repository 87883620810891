.contact {
  &-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background: linear-gradient(
      to bottom,
      rgba(255, 148, 148, 0.3) 0%,
      rgba(133, 115, 255, 0.3) 100%
    );
    color: black;
    padding: 10px 20px;
    border-radius: 28px;
    box-shadow: 0 0 10px #fff;
    cursor: pointer;
    z-index: 999;
    width: 200px;
    height: 50px;
    font-size: 18px;
    font-weight: 400;

    @media (max-width: 500px) {
      bottom: 50px;
    }

    @media (max-width: 1000px) {
      background: black;
      color: white;
      width: fit-content;
      height: fit-content;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &-form {
    width: 60%;
    max-width: 60%;
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;

    &-response {
      text-align: right;
      padding: 10px 0;
    }

    &-field {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &-required {
        color: #d63637;
      }

      @media (max-width: 710px) {
        flex-direction: column;
      }
    }

    &-submit {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;

      @media (max-width: 710px) {
        flex-direction: column;
      }
    }

    h2 {
      margin-top: 0;
      margin-bottom: 20px;
      text-align: center;
      font-size: 36px;
      font-weight: 700;
    }

    form {
      display: flex;
      flex-direction: column;
    }

    label {
      margin: auto 0;
      font-weight: 400;
    }

    input,
    textarea {
      margin-bottom: 10px;
      padding: 10px;
      width: 80%;

      @media (max-width: 710px) {
        width: 100%;
      }
    }

    input::placeholder,
    textarea::placeholder {
      color: gray;
    }

    input {
      border-bottom: 1px solid #ccc;
    }

    textarea {
      border: 1px solid #ccc;
      border-radius: 5px;
      height: 120px;
    }

    button[type='submit'] {
      padding: 17px 30px;
      background: white;
      color: black;
      border: 1px solid black;
      border-radius: 5px;
      cursor: pointer;
      height: fit-content;

      @media (max-width: 710px) {
        margin-top: 10px;
      }
    }

    button[type='submit']:hover {
      border: none;
      box-shadow:
        0px 4px 15px 0px rgba(93, 95, 239, 0.6),
        0px -4px 15px 0px rgba(235, 0, 0, 0.3);
    }

    @media (max-width: 500px) {
      max-width: 100%;
      width: 100%;
      max-height: 95vh;
      height: 95vh;
      overflow-y: auto;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: 1px solid black;
  border-radius: 50%;
  padding: 3px;
  font-size: 18px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: black;
}

.close-button:hover {
  color: gray;
  border: 1px solid gray;
  box-shadow: 0 0 10px gray;
}
